/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React from "react"
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import * as TextConst from "../userSearchConstant"
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as ErrorConst from "../../../SharedModules/Messages/ErrorMsgConstants_copy";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    }
}));

function UserSearchForm(props) {
    const classes = useStyles();
    return (
        < >        
        <form autoComplete="off">
            <div className="mt-3">
                <div className="form-wrapper p-0">
                <div className="mui-custom-form input-md ">
                {/* <label className="MuiFormLabel-root MuiInputLabel-shrink">Void</label> */}
                    <TextField
                        autoFocus={true}
                        inputRef={props.nameRef}
                        id="last-name"
                        label={TextConst.LAST_NAME}
                        className="inline-lable-ttl"                        
                        inputProps={{ maxLength: 35,style:{textTransform:'uppercase'} }}
                        value={props.formValue.lastName}
                        onChange={props.handleChange('lastName')}
                        placeholder=" "
                        
                        helperText={props.errors.lastNameErr ? ErrorConst.LAST_NAME_REQ_ERR : props.errors.lastNameStartwithErr ? ErrorConst.PTP_REQ_TWO_CHAR : null}
                        InputLabelProps={{
                            shrink: true,
                            required:true
                        }}
                        error={props.errors.lastNameErr ? ErrorConst.LAST_NAME_REQ_ERR : props.errors.lastNameStartwithErr ? ErrorConst.PTP_REQ_TWO_CHAR : null}
                    />
                    <div className="sub-radio">
						<Checkbox
							value={true}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							id="StartsWith-first-proc-code"
							checked={props.formValue.startsWithLastName === true}
							onChange={props.handleChange('startsWithLastName')}
						/>
						<label className="text-black" htmlFor="StartsWithCheckbox">{TextConst.SELECT_WITH}</label>
					</div>
                </div>
                <div className="mui-custom-form input-md ">
                    <TextField
                        id="first-name"
                        label={TextConst.FIRST_NAME}
                        className="inline-lable-ttl"
                        value={props.formValue.firstName}
                        onChange={props.handleChange('firstName')}
                        inputProps={{ maxLength: 25 ,style:{textTransform:'uppercase'}}}
                        placeholder=""
                       // helperText={props.errors.showSecondProcErr ? ErrorConst.PTP_REQ_TWO_CHAR : null}
                        InputLabelProps={{
                            shrink: true
                        }}
                        // error={props.errors.showSecondProcErr ? ErrorConst.PTP_REQ_TWO_CHAR : null}
                    />                    
                </div>
                
                <div className="mui-custom-form with-select input-md">
					<TextField
						id="mi"						
						label={TextConst.MI}
						 value={props.formValue.middleInitial}
						inputProps={{ maxLength: 1,style:{textTransform:'uppercase'} }}
						 onChange={props.handleChange('middleInitial')}
						placeholder=""						
						InputLabelProps={{
							shrink: true,
						}}						
					/>
				</div>
                <div className="mui-custom-form with-select input-md">
					<TextField
						id="ncci-type-code"						
						label={TextConst.EMAIL}
						 value={props.formValue.email}
						inputProps={{ maxLength: 99 , style:{textTransform:'uppercase'} }}
						 onChange={props.handleChange('email')}
						placeholder=""						
						InputLabelProps={{
							shrink: true,
                        }}	
                        helperText={props.errors.emailErr ? ErrorConst.INV_EMAIL : null}
                         error={props.errors.emailErr ? ErrorConst.INV_EMAIL : null}					
					/>
				</div>
                        <div className="mui-custom-form with-select input-md mlr-0">
                        {/* <div className="MuiFormLabel-root MuiInputLabel-shrink">User Status</div> */}
                           
                        <FormControl className={classes?.formControl}>
                                <InputLabel id="user-role-mutiple-checkbox-label">User Status</InputLabel>
                                <Select
                                    id="user-role-mutiple-checkbox"
                                    multiple
                                    value={props.formValue.userStatus}
                                    onChange={props.handlechengeStatus('userStatus')}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        if (selected.length == 1 && selected[0] == '-1') {
                                            return TextConst.USER_STATUS_PLACEHOLDER;
                                        }
                                        let showText = [];
                                        props.statusList.map(e => {
                                            if (selected.includes(e)) {
                                                showText.push(e)
                                            }
                                        })
                                        return showText.join(', ');
                                      }}
                                >
                                    {props.statusList.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={props.formValue.userStatus.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                          
                        </div>

                </div>
            </div>
            <div className="tabs-container pb-3">
                <div className="tab-header pt-3">               
                    <div className="float-right th-btnGroup mb-2">
                        <Button
                            title="Search"
                            id="search-btn"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-search"
                           // disabled={!props.privileges.search}
                            onClick={props.onSearch}
                        >
                            {' '+TextConst.SEARCH_BUTTON_LABEL+' '}
                        </Button>
                        <Button
                            title="Reset"
                            id="reset-btn"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-reset"
                            onClick={props.resetTable}
                        >
                            {' '+TextConst.RESET_BUTTON_LABEL+' '}
                        </Button>
                    </div>
                    <div className="clearfix" />
                </div>
                

            </div>
            
        </form>
        </>
    )
}


export default UserSearchForm;