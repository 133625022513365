/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React, { useState, useEffect, useRef } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../../../SharedModules/TabPanel/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router';
import Spinner from "../../../SharedModules/Spinner/Spinner";
import { Button } from "react-bootstrap";
import * as ErrorConst from "../../../SharedModules/Messages/ErrorMsgConstants_copy";
import BreadCrumbs from "../../../SharedModules/BreadCrumb/BreadCrumb";
import { resetByNameAction, SearchByNameAction, searchByIdAction, resetByIdAction } from "../searchActions";
import { getUserDetailsAction, dispatchResetUserDetails } from '../action';
import UserSearchTable from "./UserSearchTable";

import { setPrintLayout, setUserprivileges } from "../../../SharedModules/Store/Actions/SharedAction";
import * as TextConst from "../userSearchConstant";
import ReactToPrint from "react-to-print";
import UserSearchForm from './UserSearchForm';
import UserIdSearchForm from './UserIdSearchForm';
import { MANAGE_ACCOUNT_ADDEDIT } from "../../../SharedModules/Navigation/RouteConstants";
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const UserSearch  = (props) => {
  let errorMessagesArray = [];
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);

  const payloadSearchData = useSelector((state) => state.userManagement.userByNameSearchData);
  const searchByIdData = useSelector((state) => state.userManagement.searchByIdData);
  const [showSearchTable, setshowSearchTable] = useState(false);
  const userData = useSelector((state) => state.sharedState.userPrivileges && state.sharedState.userPrivileges ? state.sharedState.userPrivileges : '')
  const [successMessages, setSuccessMessages] = useState([]);
  const onUserByNameSearch = (searchvalues,fieldsValue) => {
    return dispatch(SearchByNameAction(searchvalues,fieldsValue));
  };
  const onUserByIdSearch = (searchvalues,fieldsValue) => {
    return dispatch(searchByIdAction(searchvalues,fieldsValue));
  };
  const resetTableData = () => dispatch(resetByNameAction());
  const resetTableDataById = () => dispatch(resetByIdAction());
  const getDetails = id => dispatch(getUserDetailsAction(id));
  const resetDetails = () => dispatch(dispatchResetUserDetails());
  const [{ lastNameErr,lastNameStartwithErr, emailErr}, setShowPtpErr] = useState(false);
  const [showNoRecords, setShowNoRecords] = useState(false);
  const [userIdReqErr, setUserIdReqErr] = useState(false); 
  const [redirect, setRedirect] = useState(false);
  const [redirectById, setRedirectById] = useState(false);
  const defaultSearchByNameObj = {
    firstName:"",
    startsWithLastName: false,
    middleInitial:"",
    userStatus: ['-1'],
    lastName: "",
    email: "",
    active: "",
    inActive: "",
    locked: ""
  }
  const defaultValueForById={
    userId:""
  }
  const [values,setValues]=React.useState(defaultValueForById);
  const [formValue, setformValue] = useState(defaultSearchByNameObj);
  const [errorMessages, seterrorMessages] = useState([]);
  const printRef = useRef();
  const [mueSearchFormErr, setMueSearchFormErr] = React.useState([]);
  const userDetailsData = useSelector(state => state.userManagement.userDetailsResponse);
  const ptpfieldsValue = useSelector(state => state.userManagement.searchByNameFields);
  const [urlSearch, setUrlSearch] = useState('');
  const nameRef = useRef()
  const userIDRef = useRef()
 
const statusList = [
  'Active',
  'Inactive',
  'Locked'  
];

  useEffect(() => {
    if (userDetailsData &&  (redirect || redirectById)) {
      setRedirect(false);
      setspinnerLoader(false);
      props.history.push({
        pathname: MANAGE_ACCOUNT_ADDEDIT,
        state:{tabValue:redirect?0:redirectById?1:"",searchCriteria:redirect?formValue:redirectById?values:"" }
      });
      resetTableDataById();
    }
  }, [userDetailsData]);

  useEffect(() => {if (urlSearch != '') searchCheck()},[urlSearch]);

  // To remove search Params generated from login portal
  const clearSearchParams = () => {
    props.history.replace({
      pathname: props.location.pathname,
      search: ''
    });
  };
  useEffect(() => {
    resetDetails();
    if (props.location.state && props.location.state.passwordUpdated) {
      setSuccessMessages([`Password has been changed successfully for the user ${userData.userID}. The session remains active and the user may proceed.`]);
    }
    if (props.location.state && props.location.state.passwordCancel) {
      let menus={...userData};
    menus.passwordChanged=false;    
    dispatch(setUserprivileges(menus)); 
    }
    if(props&&props.location&&props.location.search){
      const urlParams = new URLSearchParams(props.location.search);

      let userID = urlParams.get('userId');
      let tabValue = urlParams.get('tabValue');
      if(userID && (tabValue == 0 || tabValue ==1))
      {
      setValues({...values, userId:userID?.toUpperCase()});
      setTabValue(tabValue);
      setUrlSearch(new Date());
    }
      }
      if(props.location.state && props.location.state.searchCriteria){
        
        if(props.location.state.tabValue === 0){
          setTabValue(props.location.state.tabValue)
          setformValue({...props.location.state.searchCriteria})
        }else if(props.location.state.tabValue === 1){
          setTabValue(props.location.state.tabValue)
          setValues({...props.location.state.searchCriteria})
        }
      }
     clearSearchParams() 
  }, [])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if(newValue === 0){
      setTimeout(()=>{nameRef?.current?.focus()},200)
      
    }else if(newValue === 1)
    {
      setTimeout(()=>{userIDRef?.current?.focus()},200)

      
    }
  };
  const handlechengeStatus = (name) => (event)=>{
    if (event.target.value.length > 1 && event.target.value[0] == '-1') {
      setformValue({...formValue, [name]: event.target.value.slice(1)});
  } else if (event.target.value.length == 0) {
      setformValue({...formValue, [name]: ['-1']});
  } else {
      setformValue({...formValue, [name]: event.target.value});
  }
};
  const handleChange = (name) => (event) => {
    if (name === 'startsWithLastName') {
      setformValue({ ...formValue, [name]: event.target.checked });
    }
    else {
      if (name === 'firstName' || name === 'middleInitial' || name === 'lastName' || name === 'email') {
        setformValue({ ...formValue, [name]: event.target.value });
      } else {
        setformValue({ ...formValue, [name]: event.target.value });
      }
    }
  };
  const handleChanges = (name) => (event) => {
    setValues({...values, [name]:event.target.value})
  } 
  // reset table
  const resetTable = () => {
    setshowSearchTable(false);
    setShowPtpErr(false);
    seterrorMessages([]);
    setSuccessMessages([]);
    setShowNoRecords(false);
    setformValue(defaultSearchByNameObj);
    resetTableData();
  };
  const resetTableByID = () => { 
    setUserIdReqErr(false) 
    seterrorMessages([]);
    setSuccessMessages([]);
    setShowNoRecords(false);
    setValues(defaultValueForById);
    resetTableDataById();
  };
  const formValidation = () =>{
    errorMessagesArray = [];
    seterrorMessages([]);
    setSuccessMessages([]);
    let reqFieldArr = [];
    let lastName=false;
    let lastNameStartwith=false;
    let emailVal=false;
    const EMAIL_ADDRESS_REGEX = /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]){2,4}$/;
    if(formValue.lastName ==="" && !formValue.startsWithLastName &&  formValue.firstName ==="" && formValue.middleInitial ==="" && formValue.email ==="" && formValue.userStatus[0] == "-1"){
      reqFieldArr.push(ErrorConst.ENTER_SEARCH_CRITERIA);
    }
    if(formValue.lastName==="" && !formValue.startsWithLastName){
      lastName=true;
      reqFieldArr.push(ErrorConst.LAST_NAME_REQ_ERR);
    }
    if(formValue.startsWithLastName && formValue.lastName.length < 2){
      reqFieldArr.push(ErrorConst.PTP_REQ_TWO_CHAR);
      lastNameStartwith=true
    } 
    if(formValue.email){
      if (!EMAIL_ADDRESS_REGEX.test(formValue.email)) {
        reqFieldArr.push(ErrorConst.INV_EMAIL);
        emailVal= true;
    }
    } 
    if (reqFieldArr.length) {
      setShowPtpErr({ lastNameErr:lastName, lastNameStartwithErr:lastNameStartwith,emailErr:emailVal });
      seterrorMessages(reqFieldArr);
      return false;
    }
    return true;
  }
  const onSearch = () => {
    setshowSearchTable(false);
    setspinnerLoader(false);
    setShowNoRecords(false);
    setShowPtpErr(false);
    if(formValidation()){
      setspinnerLoader(true);
      let searchCriteria = {};  
      searchCriteria={
        "firstName": formValue.firstName ? formValue.firstName.toUpperCase() : "",
        "middleInitial": formValue.middleInitial ? formValue.middleInitial.toUpperCase() : "",
        "lastName": formValue.lastName ? formValue.lastName.toUpperCase() : "",
        "active":formValue.userStatus[0] !== '-1' && formValue.userStatus.includes("Active") ? true : false,
        "inActive":formValue.userStatus[0] !== '-1' && formValue.userStatus.includes("Inactive") ? true : false,
        "locked":formValue.userStatus[0] !== '-1' && formValue.userStatus.includes("Locked") ? true : false,
        "startsWithLastName":formValue.startsWithLastName,
        "email":formValue.email ? formValue.email.toUpperCase() : ""
      };      
      onUserByNameSearch(searchCriteria,formValue);
    }
  };
  const searchCheck = () => {   
    setShowNoRecords(false);
    setUserIdReqErr(false)  
    seterrorMessages([]); 
    setSuccessMessages([]);
    if (values.userId==="") {
      setUserIdReqErr(true);
      seterrorMessages([ErrorConst.USER_ID_REQ_ERR]);
      return false;
    }
    setspinnerLoader(true);
    onUserByIdSearch({userId:values.userId.toUpperCase()},values)
  }

   useEffect(() => {
    if (payloadSearchData != null && payloadSearchData.statusCode=="01" && payloadSearchData.responseObject && payloadSearchData.responseObject.length > 1){
      setshowSearchTable(true);
      setspinnerLoader(false);
    }
    if (payloadSearchData && (payloadSearchData.message != null || payloadSearchData.message !== "") && !payloadSearchData.statusCode){
      setspinnerLoader(false);
     errorMessagesArray.push(ErrorConst.ERROR_OCCURED_DURING_TRANSACTION);
     seterrorMessages(errorMessagesArray);
    }
    if (payloadSearchData != null && payloadSearchData.statusCode=="02"){
      setspinnerLoader(false);
      setShowNoRecords(true);
      resetTableData()
    }
    if (payloadSearchData != null && payloadSearchData.statusCode=="01" && payloadSearchData.responseObject && payloadSearchData.responseObject.length===0){
      setspinnerLoader(false);
      setShowNoRecords(true);
      resetTableData()
    }
    if (payloadSearchData && payloadSearchData.statusCode=="01" && payloadSearchData.responseObject && payloadSearchData.responseObject.length === 1) {
      // userDetails service will call
      setspinnerLoader(true);
      getDetails(payloadSearchData.responseObject[0].userName);
      setRedirect(true);
      setshowSearchTable(false);
      resetTableData();
    }
   }, [payloadSearchData]);

   useEffect(() => {  
    if (searchByIdData && (searchByIdData.message != null || searchByIdData.message !== "") && !searchByIdData.statusCode){
      setspinnerLoader(false);
     errorMessagesArray.push(ErrorConst.ERROR_OCCURED_DURING_TRANSACTION);
     seterrorMessages(errorMessagesArray);
    }
    if (searchByIdData != null && searchByIdData.statusCode=="02"){
      setspinnerLoader(false);
      setShowNoRecords(true);
    }
    if (searchByIdData && searchByIdData.statusCode=="01" && searchByIdData.responseObject && searchByIdData.responseObject.userName !== null) {
      setspinnerLoader(true);
      getDetails(searchByIdData.responseObject.userName);
      setRedirectById(true);
    }
   }, [searchByIdData]);

   const removeAllError = () =>{
    seterrorMessages([]);
    setSuccessMessages([]);
    setMueSearchFormErr([]);
    setShowNoRecords(false);
    setUserIdReqErr(false);
    setShowPtpErr(false);
   }
   const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className="pos-relative">
      {spinnerLoader ? <Spinner setspinnerLoader= {setspinnerLoader} /> : null}
      {successMessages.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {errorMessages.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {mueSearchFormErr.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {mueSearchFormErr.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {showNoRecords ? (
        <div className="alert alert-danger custom-alert" role="alert">
          <li>{ErrorConst.NO_RECORDS_WITHSEARCH}</li>
        </div>
      ) : null}
      <div className="tabs-container" ref={printRef}>
        <div className="tab-header">
          <h1 className="page-heading float-left">{TextConst.PAGE_HEADING}</h1>
          <div className="float-right th-btnGroup">
          <Button title={TextConst.ADD_USER_BUTTON_TITLE} 
            variant="outlined" 
            color="primary" 
            className="btn btn-ic btn-add" 
           // disabled={props.privileges && !props.privileges.add }
            onClick={() => {resetDetails();
              props.history.push({ pathname: MANAGE_ACCOUNT_ADDEDIT });}}
          >
            {TextConst.ADD}
          </Button>
          </div>
        </div>
        <div className="rquired-header">* Required Field</div>
        <div className="custom-hr my-1 pb-1" />
        <div className="tab-body clm-correction-panel-1">
          <div className="tab-holder custom-tabber mt-3">
          <AppBar position='static' color="default">
            <Tabs value={tabValue}
              onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example">
                <Tab className="text-transform-none" title={TextConst.TAB1_HEADING} label={TextConst.TAB1_HEADING} {...a11yProps(0)} onClick={removeAllError}/>
                <Tab title={TextConst.TAB2_HEADING} label={TextConst.TAB2_HEADING} {...a11yProps(1)} onClick={removeAllError} />
            </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
            <div id="simple-tab-0" className="visuallyhidden">{TextConst.TAB1_HEADING}</div>
            <div id="simple-tab-1" className="visuallyhidden">{TextConst.TAB2_HEADING}</div>
            <UserSearchForm
            formValue={formValue}
            nameRef={nameRef}
            resetTable={resetTable}
            onSearch={onSearch}
            statusList={statusList}
            handleChange={handleChange}
            handlechengeStatus={handlechengeStatus}
            errors={{lastNameErr,lastNameStartwithErr,emailErr}}
            privileges={props.privileges} />
                {showSearchTable ? (
                  <div className="tab-body py-2">
                    <div className="fw-600 mb-2">{TextConst.SEARCH_RESULT}</div>
                   <UserSearchTable
                      tableData={payloadSearchData && payloadSearchData.responseObject ? payloadSearchData.responseObject : []}
                      setspinnerLoader={setspinnerLoader}
                      setRedirect={setRedirect}
                      redirect={redirect}
                      seterrorMessages={seterrorMessages}
                    />
                  </div>
                ) : null}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <UserIdSearchForm 
                values={values}  
                userIDRef={userIDRef}
                userIdReqErr={userIdReqErr}
                handleChanges={handleChanges} 
                searchCheck={searchCheck} 
                resetTable={resetTableByID} 
                privileges={props.privileges}
            />            
            </TabPanel>
          </div>
        </div>
        
      </div>
    </div>
  )
}
export default withRouter(UserSearch);
