/**
 * © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
 * Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
 * States and/or other countries.
 */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import { Button } from 'react-bootstrap';
import * as ErrorConst from '../../../../SharedModules/Messages/ErrorMsgConstants_copy';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import NavigationPrompt from 'react-router-navigation-prompt';
import * as ManageAccTextConst from '../../ManageAccountConstants';
import RolesWorkUnit from './RolesWorkUnit';
import UserDetails from './UserDetails';
import Action from './Action';
import { resetPasswordAction, updateUserAction } from '../../action';
import {
  UserRolesDropdownActions,
  WorkUnitsDropdownActions,
  AppConfigDropdownActions,
} from '../../../../SharedModules/DropdownService/Actions/AppActions';
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import { GET_SYSTEMLIST_DROPDOWN } from "../../../../SharedModules/Dropdowns/actions";
import { USER_SEARCH } from '../../../../SharedModules/Navigation/RouteConstants';


const EditManageAccount = props => {
  const dispatch = useDispatch();
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [values, setValues] = React.useState({
    userId: '',
    userStatus: '',
    organization: '',
    lastLogin: '',
    passwordExpires: '',
    deactivate: '-1',
    lock: '-1',
    reasonAction: '',
    prefix: '-1',
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '-1',
    phone: '',
    email: '',
    userRole: [],
    workUnit: [],
  });
  const [defaultValues, SetDefaultValues] = React.useState(values);
  const [
    {
      firstNameReq,
      lastNameReq,
      isFirstNameValid,
      isMiddleNameValid,
      isLastNameValid,
      phoneNumberReq,
      emailReq,
      userRolesReq,
      workUnitsReq,
      emailInvErr,
      phoneInvErr,
      deactiveReasonReq,
      lockReasonReq,
    },
    setErrors,
  ] = React.useState(false);
  const errorMsg = {
    lockMsg: `Please enter the reason for locking/unlocking user ${values.userId}.`,
    deactivateMsg: `Please enter the reason for the change in user ${values.userId} account status change.`,
  };
  const [confirm, setConfirm] = React.useState(false);
  const [prompt, setPrompt] = React.useState(false);
  const dropDownUserRoles = () => dispatch(UserRolesDropdownActions());
  const dropDownWorkUnits = () => dispatch(WorkUnitsDropdownActions());
  const onDropdowns = (values) => dispatch(GET_SYSTEMLIST_DROPDOWN(values));

  const dropDownDispatch = configKeyList =>
    dispatch(AppConfigDropdownActions(configKeyList));
  const userRolesDropdownData = useSelector(
    state => state.sharedState.userRolesDropdown
  );
  const sysDropdowns = useSelector((state) => state.appDropDowns.sysdropdowns);

  const workUnitsDropdownData = useSelector(
    state => state.sharedState.workUnitsDropdown
  );
  const dropdownData = useSelector(
    state => state.sharedState.appConfigDropdown
  );
  const userDetailsData = useSelector(
    state => state.userManagement.userDetailsResponse
  );
  useEffect(() => {
    if (userDetailsData && userDetailsData.responseObject) {
      let listUserRoles = [];
      let listWorkUnit = [];
      if (
        userDetailsData.responseObject.userRoles &&
        userDetailsData.responseObject.userRoles.length > 0
      ) {
        userDetailsData.responseObject.userRoles.map(each => {
          listUserRoles.push(each._id);
        });
      }
      if (
        userDetailsData.responseObject.workUnits &&
        userDetailsData.responseObject.workUnits.length > 0
      ) {
        userDetailsData.responseObject.workUnits.map(each => {
          listWorkUnit.push(each._id);
        });
      }
      let data = userDetailsData.responseObject;
      setValues({
        userId: data.userName,
        userStatus: data.accountStatus,
        organization: data.organization,
        lastLogin: data.lastLogin === "----------"?"":data.lastLogin,
        passwordExpires: data.passwordExpiry,
        deactivate: !data.activate,
        lock: data.locked,
        reasonAction: data.reason,
        prefix: data.prefix ? data.prefix : '-1',
        firstName: data.givenName,
        lastName: data.sn,
        middleName: data.middleName,
        suffix: data.suffix ? data.suffix : '-1',
        phone: data.telephoneNumber,
        email: data.mail,
        userRole: listUserRoles.length > 0 ? listUserRoles : [],
        workUnit: listWorkUnit.length > 0 ? listWorkUnit : [],
      });
      SetDefaultValues({
        userId: data.userName,
        userStatus: data.accountStatus,
        organization: data.organization,
        lastLogin: data.lastLogin === "----------"?"":data.lastLogin,
        passwordExpires: data.passwordExpiry,
        deactivate: !data.activate,
        lock: data.locked,
        reasonAction: data.reason,
        prefix: data.prefix ? data.prefix : '-1',
        firstName: data.givenName,
        lastName: data.sn,
        middleName: data.middleName,
        suffix: data.suffix ? data.suffix : '-1',
        phone: data.telephoneNumber,
        email: data.mail,
        userRole: listUserRoles.length > 0 ? listUserRoles : ['-1'],
        workUnit: listWorkUnit.length > 0 ? listWorkUnit : ['-1'],
      });
    }
    if (saveUserDetailsResponse) {
      setspinnerLoader(false);
      if (saveUserDetailsResponse.statusCode == '01') {
        setSuccessMessages(['The user is created successfully']);
      }
    }
  }, [userDetailsData]);

  const saveUserDetailsResponse = useSelector(
    state => state.userManagement.saveUserDetailsResponse
  );
  const resetPassword = (userId, revId) =>
    dispatch(resetPasswordAction(userId, revId));
  const resetPasswordResponse = useSelector(
    state => state.userManagement.resetPasswordResponse
  );
  const resetPasswordTime = useSelector(
    state => state.userManagement.resetPasswordTime
  );
  const updateUser = data => dispatch(updateUserAction(data));
  const updateUserResponse = useSelector(
    state => state.userManagement.updateUserResponse
  );
  const updateUserTime = useSelector(
    state => state.userManagement.updateUserTime
  );
  const [lockChange, setLockStatus] = React.useState(null);
  const [deactivateChange, setDeactivateStatus] = React.useState(null);
  const handelPromptSet = set => {
    if (set) setPrompt(true);
  };
 
  useEffect(() => {
    onDropdowns({
      inputList: [Dropdowns.DM_SFX_NAM, Dropdowns.G_NAM_PREFX_CD]
    }); 
    const configKeyList = ['Name_Prefix', 'Name_Suffix'];
    dropDownUserRoles();
    dropDownWorkUnits();
  }, []);
  useEffect(() => {
    if (resetPasswordResponse) {
      setspinnerLoader(false);
      if (resetPasswordResponse.statusCode == '01') {
        setSuccessMessages([
          `The password of User - ${values.userId} has been reset successfully.`,
        ]);
      } else {
        seterrorMessages([
          `System fails to reset password for the user ${values.userId}.`,
        ]);
      }
    }
  }, [resetPasswordTime]);
  useEffect(() => {
    if (updateUserResponse) {
      setspinnerLoader(false);
      if (updateUserResponse.statusCode == '01') {
        if (lockChange == 'unlocked') {
          setSuccessMessages([
            `User account is successfully un-locked for ${values.userId}.`,
          ]);
        } else if (deactivateChange == 'activate') {
          setSuccessMessages([
            `User ${values.userId} account has been activated.`,
          ]);
        } else if (deactivateChange == 'deactivate') {
          setSuccessMessages([
            `User ${values.userId} account has been deactivated.`,
          ]);
        } else {
          setSuccessMessages([
            `${values.userId} profile has been successfully updated.`,
          ]);
        }
      } else {
        if (lockChange == 'unlocked') {
          seterrorMessages([
            `The system failed to unlock the user account ${values.userId}. Please contact the system adminstrator.`,
          ]);
        } else {
          seterrorMessages([
            `The system failed to update the user ${values.userId} with the updated roles and other security filters.`,
          ]);
        }
      }
    }
  }, [updateUserTime]);
  const handleValuesChanges = name => event => {
    if (name === 'deactivate' || name === 'lock') {
      setValues({ ...values, [name]: event.target.value, reasonAction: '' });
    } else {
      if (
        name === 'firstName' ||
        name === 'lastName' ||
        name === 'middleName' ||
        name === 'email'
      ) {
        setValues({ ...values, [name]: event.target.value });
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    }
  };
  const [isReset,setIsReset] = useState(false)
  const handelReset = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    setValues({...defaultValues});
    setErrors(false);
    setIsReset(!isReset)
  };
  const handelCancel = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    if (JSON.stringify(values) == JSON.stringify(defaultValues)) {
      setConfirm(true);
    }
    setTimeout(() => {
      props.history.push({
        pathname: USER_SEARCH,
        state:{...props.location?.state}
      });
    }, 200);
  };
  const handelSave = () => {
    let errorMessagesArray = [];
    setSuccessMessages([]);
    seterrorMessages([]);
    setspinnerLoader(true);
    setErrors(false);
    setDeactivateStatus(null);
    setLockStatus(null);
    const EMAIL_ADDRESS_REGEX =
      /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]){2,4}$/;
    const nameRegex = /^[a-zA-Z\s]+$/;
    const isUserNameValid = (name) => nameRegex.test(name)

    setErrors({
      firstNameReq: !values.firstName
        ? (() => {
            errorMessagesArray.push(ErrorConst.FIRST_NAME_REQ);
            return true;
          })()
        : false,
      lastNameReq: !values.lastName
        ? (() => {
            errorMessagesArray.push(ErrorConst.LAST_NAME_REQ_ERR);
            return true;
          })()
        : false,
      
      isFirstNameValid: values.firstName && !isUserNameValid(values.firstName) ? (() => { errorMessagesArray.push(ErrorConst.INVALID_USER_FIRST_NAME); return true; })() : false,
      isMiddleNameValid: values.middleName && !isUserNameValid(values.middleName) ? (() => { errorMessagesArray.push(ErrorConst.INVALID_USER_MIDDLE_NAME); return true; })() : false,
      isLastNameValid: values.lastName && !isUserNameValid(values.lastName) ? (() => { errorMessagesArray.push(ErrorConst.INVALID_USER_LAST_NAME); return true; })() : false,
      phoneNumberReq: !values.phone
        ? (() => {
            errorMessagesArray.push(ErrorConst.PHONE_REQ);
            return true;
          })()
        : false,
      emailReq: !values.email
        ? (() => {
            errorMessagesArray.push(ErrorConst.EMAIL_REQ);
            return true;
          })()
        : false,
      userRolesReq:
        values.userRole.length < 1 
          ? (() => {
              errorMessagesArray.push(ErrorConst.USER_ROLES_REQ);
              return true;
            })()
          : false,
      workUnitsReq:
        values.workUnit.length < 1 
          ? (() => {
              errorMessagesArray.push(ErrorConst.WORK_UNITS_REQ);
              return true;
            })()
          : false,
      emailInvErr:
        values.email && !EMAIL_ADDRESS_REGEX.test(values.email)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INV_CONTENT);
              return true;
            })()
          : false,
      phoneInvErr:
        values.phone && !/^[0-9-+]+$/.test(values.phone)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INV_CONTENT);
              return true;
            })()
          : false,
      deactiveReasonReq:
        values.deactivate != defaultValues.deactivate && !values.reasonAction
          ? (() => {
              errorMessagesArray.push(errorMsg.deactivateMsg);
              return true;
            })()
          : false,
      lockReasonReq:
        values.lock != defaultValues.lock &&!values.reasonAction
          ? (() => {
              errorMessagesArray.push(errorMsg.lockMsg);
              return true;
            })()
          : false,
    });
    if (errorMessagesArray.length > 0) {
      seterrorMessages(errorMessagesArray);
      setspinnerLoader(false);
      return false;
    }
    if (values.lock != defaultValues.lock && !values.lock) {
      setLockStatus('unlocked');
    }
    if (values.deactivate != defaultValues.deactivate) {
      if (values.deactivate) {
        setDeactivateStatus('deactivate');
      } else {
        setDeactivateStatus('activate');
      }
    }
    let data = {
      workUnits: workUnitsDropdownData.filter(e =>
        values.workUnit.includes(e._id)
      ),
      userRoles: userRolesDropdownData.responseObject.filter(e =>
        values.userRole.includes(e._id)
      ),
      userName: values.userId,
      prefix: values.prefix != '-1' ? values.prefix : '',
      givenName: values.firstName ? values.firstName.toUpperCase() : '',
      middleName: values.middleName ? values.middleName.toUpperCase() : '',
      sn: values.lastName ? values.lastName.toUpperCase() : '',
      suffix: values.suffix != '-1' ? values.suffix : '',
      mail: values.email ? values.email.toUpperCase() : '',
      telephoneNumber: values.phone,
      reason: values.reasonAction,
      locked: values.lock,
      activate: values.deactivate === "true"?false:true,
    };
    updateUser(data);
  };
  const handelResetPassword = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    setspinnerLoader(true);
    setErrors(false);
    if (values.deactivate || values.lock) {
      seterrorMessages([ErrorConst.INACTIVE_RESET_PASSWORD]);
      setspinnerLoader(false);
      return false;
    }
    resetPassword(
      userDetailsData.responseObject._id,
      userDetailsData.responseObject._rev
    );
  };
  return (
    <div className="pos-relative">
      {spinnerLoader ? (
        <Spinner
          spinnerLoader={spinnerLoader}
          setspinnerLoader={setspinnerLoader}
        />
      ) : null}
      {errorMessages.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </div>
      ) : null}
      {successMessages.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </div>
      ) : null}
      <div className="tabs-container">
        <div className="tab-header">
          <h1 className="page-heading float-left">
            {ManageAccTextConst.EDIT_PAGE_HEADING}
          </h1>
          <div className="float-right th-btnGroup">
            <Button
              title="Update"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-save"
              onClick={handelSave}
            >
              {ManageAccTextConst.UPDATE_BUTTON}
            </Button>
            <Button
              title="Reset Password"
              variant="outlined"
              color="primary"
              className="btn btn-cancel btn-lock padl-25"
              onClick={handelResetPassword}
            >
              {ManageAccTextConst.RESET_PASSWORD_BUTTON}
            </Button>
            <Button
              title="Reset"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-reset"
              onClick={handelReset}
            >
              {ManageAccTextConst.RESET_BUTTON_LABEL}
            </Button>
            <Button
              title="Cancel"
              variant="outlined"
              color="primary"
              className="btn btn-cancel"
              onClick={handelCancel}
            >
              {ManageAccTextConst.CANCEL_BUTTON}
            </Button>
          </div>
        </div>
        <div className="rquired-header">* Required Field</div>
        <div className="custom-hr my-1 pb-1" />
        <div className="tab-body mt-2">
          <div className="tab-body m-3 py-3">
            <Action
              values={values}
              defaultValues={defaultValues}
              handleValuesChanges={handleValuesChanges}
              errors={{ deactiveReasonReq, lockReasonReq }}
              errorMsg={errorMsg}
            />
            <UserDetails
              type="Edit"
              values={values}
              handleValuesChanges={handleValuesChanges}
              // dropdownData={dropdownData}
              dropdowns={sysDropdowns}
              errors={{
                firstNameReq,
                lastNameReq,
                isFirstNameValid,
                isMiddleNameValid,
                isLastNameValid,
                phoneNumberReq,
                emailReq,
                emailInvErr,
                phoneInvErr,
              }}
            />
            <RolesWorkUnit
              userRoleList={userRolesDropdownData}
              workUnitList={workUnitsDropdownData}
              values={values}
              defaultValues={defaultValues}
              isReset={isReset}
              setIsReset={setIsReset}
              setValues={setValues}
              errors={{ userRolesReq, workUnitsReq }}
            />
          </div>
        </div>
        
      </div>
      <NavigationPrompt
        when={(crntLocation, nextLocation) => {
          if (confirm) {
            return false;
          } else {
            handelPromptSet(nextLocation);
            return true;
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <Dialog
            open={prompt}
            onClose={() => {
              setPrompt(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box-inject"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <>
                  {ManageAccTextConst.POPUP_UNSAVED_MSG1} <br />
                  {ManageAccTextConst.POPUP_UNSAVED_MSG2}
                </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setPrompt(false);
                  onCancel();
                }}
                color="primary"
                className="btn btn-transparent"
              >
                {ManageAccTextConst.POPUP_STAY_ON}
              </Button>
              <Button
                onClick={onConfirm}
                color="primary"
                className="btn btn-success"
                autoFocus
              >
                {ManageAccTextConst.POPUP_STAY_CONTINUE}{' '}
                <i className="fa fa-arrow-right ml-1"></i>
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </NavigationPrompt>
    </div>
  );
};
export default withRouter(EditManageAccount);
