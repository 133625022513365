/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
export const BREADCRUMB_PARENT = "Manage Account";
export const BREADCRUMB_CHILD1 = "Search";
export const BREADCRUMB_PATH = "UserSearch";
export const BREADCRUMB_CHILD2 = "Add";
export const BREADCRUMB_CHILD3 = "Edit";
export const ADD_PAGE_HEADING = "Add User";
export const EDIT_PAGE_HEADING = "Edit User";
export const SAVE_BUTTON = "Save";
export const UPDATE_BUTTON = "Update";
export const RESET_BUTTON_LABEL = "Reset";
export const RESET_PASSWORD_BUTTON = "Reset Password";
export const CANCEL_BUTTON = "Cancel";
export const OK_BUTTON = "Ok";
export const POPUP_CONFIRM = "Are you sure that you want to Cancel?";
export const POPUP_UNSAVED_MSG1 = "Unsaved changes will be lost.";
export const POPUP_UNSAVED_MSG2 = "Are you sure you want to continue?";
export const POPUP_STAY_ON = "STAY ON THIS PAGE!";
export const POPUP_STAY_CONTINUE = "CONTINUE";
export const ROLES_WORKUNIT_HEADING = "Roles/Work Unit";
export const USER_ROLES = "Choose User Role";
export const USER_ROLES_PLACEHOLDER = "Please Choose User Roles";
export const WORK_UNITS = "Choose Work Unit";
export const WORK_UNITS_PLACEHOLDER = "Please Choose Work Units";
export const USER_DETAILS_HEADING = "User Details";
export const PREFIX = "Prefix";
export const PLEASE_SELECT_ONE = "Please Select One";
export const SUFFIX = "Suffix";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const MIDDLE_NAME = "Middle Name";
export const PHONE = "Phone";
export const EMAIL = "Email";
export const USER_ID = "User Id";
export const USER_STATUS = "User Status";
export const ORGANIZATION = "Organization";
export const LAST_LOGIN = "Last Login";
export const PASSWORD_EXPIRES = "Password Expires";
export const ACTION_HEADING = "Action";
export const DEACTIVATE = "Deactivate";
export const LOCK = "Lock";
export const RESION_ACTION = "What is the reason for this action?";
export const YES = "Yes";
export const NO = "No";
