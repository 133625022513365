import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./theme.scss";
import "./App.scss"
import axios from "axios";
import qs from "qs";
import { useHistory, Route } from 'react-router-dom';
import FooterLinks from "./SharedModules/Layout/FooterLinks";
import LoginPage  from './Modules/UserLogin/LoginPage'
import routes from './SharedModules/Navigation/Routes';
import Spinner from './SharedModules/Spinner/Spinner';
import ManageUser from "./Modules/Manage_User";
import Header from "./Modules/Header";
import { Button } from 'react-bootstrap';
import { getMyAccDetailsAction } from './Modules/UserSearch/action';
import ManageAccount from "./Modules/MyAccount/ManageAccount";
import { CLAIMS_APP_CODE, CMdSPortals } from "./SharedModules/AppConstants";
import { MY_ACCOUNT, USER_SEARCH } from "./SharedModules/Navigation/RouteConstants";
import * as ErrorMsgConstants from "./SharedModules/Messages/ErrorMsgConstants";


const HomePage = (props) => {  
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const uid = params.get("uid");
  const scode = localStorage.getItem("code");
  const checked = localStorage.getItem("Checked");
  const [user, setUser] = React.useState("");
  const [isNormalLogin, setIsNormalLogin] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(false || code || uid || scode);
  const [appList, setAppList] = React.useState([]);
  const [sLogoutDone, setSLogoutDone] = React.useState(false);
  const [downtime, setDownTime] = React.useState(false);
  const [upComingDowntime, setUpComingDownTime] = React.useState(false);
  const [donwTimeMsg, setDownTimeMsg] = React.useState(process.env.REACT_APP_DOWNTIME_MESSAGE);
  const getDetails = id => dispatch(getMyAccDetailsAction(id));
  const userDetailsData = useSelector(state => state.userManagement?.myAccDetailsResponse);
  const history1 = useHistory();

  const [footerLinks, setFooterLinks] = React.useState(false);
  const [footerLinksName, setFooterLinksName] = React.useState("");  
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [warningMessages, setWarningMessages] = React.useState([]);
  const [infoMessages, setInfoMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);

  let userData = JSON.parse(localStorage.getItem("loginState"));
  let tokenIntervalID = null;

  const getLWN = () => {
    return JSON.parse(localStorage.getItem("localWindowName") || "[]");
  };


  const closeWindows = () => {
    let lwn = getLWN();
    for (let i = 0; i < lwn.length; i++) {
      let x = window.open("", lwn[i]);
      x.close();
    }
    if(window.name != CMdSPortals ){
      if(localStorage.getItem('username')){
        if (lwn.indexOf(window.name) == -1) {
          if(window.opener){
            window.open("",CMdSPortals)
            window.close()
          }
          window.location = "/"
        }      
      }
}
  };
  const postToCMDSPortals = (c) => {
    let lwn = getLWN();
    for (let i = 0; i < lwn.length; i++) {
      let x = window.open("", lwn[i]);
      x?.postMessage(c, process.env.REACT_APP_CLAIMS_URL);
      x?.postMessage(c, process.env.REACT_APP_FIN_URL);
      x?.postMessage(c, process.env.REACT_APP_WP_URL);
    }
  };
  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };
  const checkTabClose = (s, n) => {
    setTimeout(() => {
      if (s.closed) {
        let lwn = getLWN();
        if (lwn.indexOf(n) > -1) {
          lwn.splice(lwn.indexOf(n), 1);
          localStorage.setItem("localWindowName", JSON.stringify(lwn));
        }
      }
    }, 100);
  };
  React.useEffect(() => {
    if(window.name != CMdSPortals ){
      window.addEventListener('beforeunload', e => {
      if(localStorage.getItem('username')){
        let lwn = getLWN();
        if (lwn.indexOf(window.name) > -1) {
          lwn.splice(lwn.indexOf(window.name), 1);
          localStorage.setItem("localWindowName", JSON.stringify(lwn));
        }      
      }
      window.onbeforeunload = null;
      window.close()
      e.stopImmediatePropagation();
    })
    window.addEventListener("storage", (event) => {
       if (event.key === "isLogOutClean"){
        if (localStorage.getItem("isLogOutClean") === "true") {
          window.close()
        }
      }
    });
  }
else {
  window.addEventListener("message", (e) => {
    if (
      e.origin === process.env.REACT_APP_CLAIMS_URL ||
      e.origin === process.env.REACT_APP_FIN_URL ||
      e.origin === process.env.REACT_APP_WP_URL
    ) {
      if (e.data === "logOut") {
        logOut();
      }
      if (e?.data && typeof(e.data)==="string" && e.data.includes('checkTabClose')) {    
        if(e.data.split('-')[1]){
          checkTabClose(e.source, e.data.split('-')[1]);
        }               
      }
    }
  });
  window.addEventListener("storage", (event) => {
    if (event.key === "username") {
      if (isLogin && !localStorage.getItem("username")) {
        logOutClean();
      } else if (localStorage.getItem("username")) {
        getAppInfo();
      }
    } else if (event.key === "initLogout") {
      if (localStorage.getItem("initLogout")) {
        logOut();
      }
    } else if (event.key === "initLogin") {
      if (localStorage.getItem("initLogin") === "true") {
        window.location = "/";
      }
    } else if (event.key === "isLogOutClean"){
      if (localStorage.getItem("isLogOutClean") === "true") {
        window.location = "/";
      }
    }
  });
}
  });
  React.useEffect(() => {
    if (isLogin) {
      setTimeout(() => {        
      getDownTime();
      }, 200);
      localStorage.setItem("isLogOutClean","false")
    }
  }, [isLogin]);
  React.useEffect(() => {
    if (uid) {
      ssoLogin();
    } else if (code) {
      localStorage.setItem("code", code);
      window.location = "/";
    } else if (!checked && localStorage.getItem("username")) {
      getAppInfo();
    } else if (scode) {
      getAccessToken(scode);
    }
    document.title = CMdSPortals;
  }, []);
  React.useEffect(() => {
    if(sLogoutDone){
      setSLogoutDone(false);
      window.location.replace(process.env.REACT_APP_SAML_LOGIN);
    }
  }, [sLogoutDone]);
  const getAccessToken = (c) => {
    var data = qs.stringify({
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      code: c,
      redirect_uri: window.location.href,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
    var config = {
      method: "post",
      url: process.env.REACT_APP_ACCESS_TOKEN,
      headers: {
        Authorization: process.env.REACT_APP_AUTHORIZATION_CODE,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getAppInfo(response);
      })
      .catch(function (error) {
        logOut();
      });
  };
  const getAppInfo = (r) => {
    let d;
    let data;
    if (r) {
      d = parseJwt(r.data.id_token);
      data = {
        username: d.stub,
        accessToken: r.data.access_token,
        refreshToken: r.data.refresh_token,
      };
    }
    else{
      data = {
        username: localStorage.getItem("username"),
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
      };
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_SECURITY_HOST}security/auth/tokenappinfo`,
      data: data,
    };
    axios(config)
      .then(function (r) {
        const d = parseJwt(r.data.data);
        setUser(`${d.lastname}, ${d.firstname}`);
        localStorage.setItem("user", `${d.lastname}, ${d.firstname}`);
        localStorage.setItem("initLogin", "true");
        sessionStorage.setItem("accessToken", r.data.accessToken);
        storeCred(
          r.data.accessToken,
          r.data.refreshToken,
          d.username,
          r.data.expiresInSeconds,
          r.data.ssoToken
        );
        storeAppInfo(d?.applist || []);
        setAppList(d?.applist || []);
      })
      .catch(function (error) {
        logOut();
      });
  };
  const storeAppInfo = (l = []) => {
    localStorage.setItem("applist", JSON.stringify(l));
  };
  const [showLink,setShowLink] = useState(["MyAcc"]);
  const refreshToken = (rToken, userName) => {
    axios
      .get(
        `${process.env.REACT_APP_SECURITY_HOST}security/token/refresh/${userName}/${rToken}`
      )
      .then((r) => {
        const d = parseJwt(r.data.data);
        storeCred(
          r.data.accessToken,
          r.data.refreshToken,
          d.username,
          r.data.expiresInSeconds,
          r.data.ssoToken
        );
      })
      .catch((e) => {
        logOut();
      });
  };
  const storeCred = (a, r, u, e, s) => {
    clearInterval(tokenIntervalID);
    postToCMDSPortals({
      accessToken: a,
      refreshToken: r,
      username: u,
      expiresInSeconds: e,
      ssoToken: s,
    });
    localStorage.setItem("accessToken", a);
    localStorage.setItem("refreshToken", r);
    localStorage.setItem("username", u);
    localStorage.setItem("expiresInSeconds", e);
    tokenInterval();
  };
  const tokenInterval = () => {
    let expTime= localStorage.getItem('expiresInSeconds');
    if(tokenIntervalID){
      clearInterval(tokenIntervalID)
    }
    if(expTime){
    tokenIntervalID = setTimeout(() => {
      refreshToken(
        localStorage.getItem("refreshToken"),
        localStorage.getItem("username")
      );
    }, expTime* 1000 - 10000);
  }
  };
  const getDownTime = () => {
    
    var config = {
      method: "get",
      url: process.env.REACT_APP_DOWNTIME,
      headers: {
        'X-CMDS-APP-CODE' : CLAIMS_APP_CODE,
        Authorization: localStorage.getItem("accessToken"),
        Username: localStorage.getItem("username"),
      },
    };

    axios(config)
      .then(function (response) {
        let dt = new Date();
        let sdt, edt, ndt, duration, durationH, durationM;
        if(response.data?.startDateTime){
          duration = response.data?.duration;
          if(duration){
            durationH = +duration.split(':')[0];
            durationM = +duration.split(':')[1];
          }          
          sdt = new Date(response.data?.startDateTime);
          edt = new Date(sdt);
          edt.setHours(edt.getHours() + durationH);
          edt.setMinutes(edt.getMinutes() + durationM);
          edt = new Date(edt);
          ndt = new Date(sdt);
          ndt.setDate(sdt.getDate() - (+process.env.REACT_APP_DOWNTIME_NOTIFY_DAYS));
          ndt = new Date(ndt);
          setDownTime((new Date(edt) > new Date(dt)) &&  (new Date(dt) > new Date(sdt)));
          setUpComingDownTime((new Date(ndt) < new Date(dt)) && (new Date(dt) < new Date(edt)));
          setDownTimeMsg(donwTimeMsg.replace('sdt', sdt.toLocaleString()).replace('edt', edt.toLocaleString()));
        }                              
      })
      .catch(function (error) {
        console.log("error=>",error,error?.data,error?.status);
      });
  };
  const ssoLogin = () => {
    window.location.replace(process.env.REACT_APP_SSO_LOGIN);
  };
  const SAMLSSOLogin = () => {
    logOut(true);    
  };
  const openTab = (url) => {
    if (downtime) {
      return false;
    }
    const wn = new Date().getTime().toString(); 
    const openedWindow = window.open(url, wn,); 
 
    if (openedWindow) {
        let lwn = getLWN(); 
        lwn.push(wn);
        localStorage.setItem("localWindowName", JSON.stringify(lwn));
    }
 };

 const removeWindowFromLocalStorage = (wn) => {
  let lwn = getLWN();
  const index = lwn.findIndex(windowInfo => windowInfo === wn);
  if (index !== -1) {
      lwn.splice(index, 1);
      localStorage.setItem("localWindowName", JSON.stringify(lwn));
  }
};

 
 

  const openClaims = () => {
    //openTab(getUrl("http://localhost:3000/"));
    openTab(getUrl(process.env.REACT_APP_CLAIMS_URL));
  };
  const getUrl = (h) => {
    return h== USER_SEARCH?`${h}?isClicked=${localStorage.getItem('accessToken')?'yes':null}&user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}&show=HOME`//Home
    :
    `${h}?user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}}`;
  };
  
  const openFin = () => {
   // openTab(getUrl("http://localhost:3001/"));
    openTab(getUrl(process.env.REACT_APP_FIN_URL));
  };
  const openWP = () => {
    openTab(getUrl(process.env.REACT_APP_WP_URL));
  };
  const [isManage,setIsManage]=useState(false)
 
  const openManageAccont = ()=>{
    localStorage.setItem("Checked","yes")
    openTab(getUrl(USER_SEARCH),'_blank')
  }
  const logOut = (lbL) => {
    if (sessionStorage.getItem("accessToken") || lbL === true) {
      localStorage.setItem("initLogout", new Date().toString());
      securityLogout(lbL);
    } else {
      if (!localStorage.getItem("initLogout")) {
        localStorage.setItem("initLogout", new Date().toString());
      }
      resetState();
      logOutClean();
    }
  };
  const resetState = () => {
    setIsLogin(false);
    setAppList([]);
    setUser("");
    setWarningMessages([]);
    setInfoMessages([]);
    setSuccessMessages([]);
    setErrorMessages([]);
    setDownTime(false);
    setUpComingDownTime(false);
  };
  const securityLogout = (lbL) => {
    axios
      .post(`${process.env.REACT_APP_SECURITY_HOST}security/auth/logout`, {
        username: localStorage.getItem("username"),
        accessToken: localStorage.getItem("accessToken"),
        ssoToken: localStorage.getItem("ssoToken"),
      })
      .then((r) => {
        samlLogout(lbL);
      })
      .catch((e) => {
        samlLogout(lbL);
      });
  };
  const logOutClean = () => {
    setIsLogin(false);
    deleteAllCookies();
    postToCMDSPortals("logOut");
    closeWindows();
    clearInterval(tokenIntervalID);
    window.localStorage.clear();
    window.sessionStorage.clear();
    localStorage.setItem("initLogin", "false");
    localStorage.setItem("Checked","no")
    localStorage.setItem("isLogOutClean","true")
  };
  const samlLogout = (lbL) => {
    let element = document.createElement("iframe");
    element.setAttribute("id", "samlframe");
    document.body.appendChild(element);
    element.style.display = "none";
    element.src = process.env.REACT_APP_SSO_LOGOUT;
    logOutClean();
    setSLogoutDone(lbL === true);
  };
  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };  
  const handleLink = (linkName) => {
    if (linkName == "PrivacyPolicy") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "TermsofUse") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "BrowserRequirements") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "AccessibilityCompliance") {
      history1.push("/FooterLinks", linkName);
      setFooterLinks(true);
      setFooterLinksName(linkName);
    } else if (linkName === "SiteMap") {
      history1.push("/SiteMap");
    }
  };

  // My Account Functionality
  const [isMyAccount,setIsMyAccount] = React.useState(false);
  
  const navigateToMyAccount =()=>{      
    callingGetDetails()
    setIsMyAccount(true);
  }
  const getMyAccountUrl = (h) => {
 
    return h==MY_ACCOUNT?`${h}?isClicked=${isMyAccount?'yes':null}&user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}&show=HOME`
    :
    `${h}?user=${localStorage.getItem('username')}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}}`;
  };
  const callingGetDetails = () => {
    let loginState = JSON.parse(localStorage.getItem('loginState'));
    getDetails(loginState?.loginUserId)
  }
  React.useEffect(() => {
  if (userDetailsData && userDetailsData?.statusCode === '01' && isMyAccount) {
    setErrorMessages([])
    setspinnerLoader(false)
    localStorage.setItem("Checked","yes")
    localStorage.setItem("userDetails", JSON.stringify(userDetailsData?.responseObject));
    openTab(getMyAccountUrl(MY_ACCOUNT),'_blank');    
    setIsMyAccount(false);
  } else if (userDetailsData && userDetailsData?.statusCode !== '01' && userDetailsData?.statusDescription !== "Success" && isMyAccount) {
    setspinnerLoader(false)
    setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION])
  }
  }, [userDetailsData, isMyAccount]);

  return (
  <> 
{footerLinks ? <FooterLinks footerLinksName ={footerLinksName} /> 
 :
  <main className="before-login ">
      <Header isLogin = {isLogin} setIsLogin ={setIsLogin} showLink={window.name != CMdSPortals?['Home']:showLink} navigateToMyAccount={navigateToMyAccount} checked={checked} logOut={logOut}/>   
  <div className={`normallogin-container ${checked && window.name != CMdSPortals && "main-body"}`}>
    {isLogin && (downtime || upComingDowntime) ? (
      <div className={`alert alert-${downtime && 'danger' || 'warning'} sso-page-alerts`}>
        <ul className="m-0">
          {<li>{downtime ? donwTimeMsg.replace('will be', 'is') : donwTimeMsg}</li>}
        </ul>
      </div>
    ) : null}
     {warningMessages.length > 0 ? (
        <div className="alert alert-warning custom-alert" role="alert">
          {warningMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
     {infoMessages.length > 0 ? (
        <div className="alert alert-info custom-alert" role="alert">
          {infoMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {successMessages.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {errorMessages.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
    <div className="container-fluid h-100">
    {
       checked == 'yes' &&  localStorage.getItem('username') && localStorage.getItem('accessToken') && window.name != CMdSPortals && window.location.pathname != '/'
      ?
      <ManageUser accessToken={localStorage.getItem('accessToken')} username={localStorage.getItem('username')}/>
      :
      <div className="row h-100 white-bg">
        <section className="col-lg-8 col-md-6 col-sm-12 banner-panel">
          <div className="log-courosal">
            <div className="log-banner">
              <img
                title="Login Banner"
                src="./images/banner.png"
                alt="login image"
              />
            </div>
          </div>
          <div className="row h-100 position-relative">
            <div className="col-md-8 col-xs-12 text-center">
              <div className="carousel-content py-2">
                <div className="carousel-text">
                  <h4> Welcome to</h4>
                  <h1>{window.name= CMdSPortals}</h1>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 px-0">
              <div className="log-action-center">
                <div className="log-notifications">
                  <h3 className="notify-heading purple-brd"> Updates</h3>
                  <div className="clear-block">
                    <div className="notify-link">Latest Policy News</div>
                  </div>
                </div>

                <div className="log-notifications">
                  <h3 className="notify-heading"> Quick Links</h3>
                  <div className="clear-block">
                    <div className="notify-link">FAQ</div>
                    <div className="notify-link">System Integration</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col-lg-4 col-md-6 col-sm-12 portal-panel">
        {spinnerLoader ? <Spinner /> : null}
          <div className="portal-wrapper">
            {isLogin ? (
              <>
                <div className="set-logOut">
                  <div className="welcome-user">
                    <h1>Hello {user ? user : userData?.lastName + ' ' + userData?.firstName}!</h1>
                    <p className="heading-text">
                      Select the suitable portal of your choice
                    </p>
                  </div>
                  <div className="portal-wrapper-inner">
                    {appList.indexOf("CLAIMS") > -1 ? (
                      <div
                        className={`portal-menu ${
                          downtime && "error-state"
                        }`}
                      >
                        <div
                          title="Claims Portal"
                          className="portal-menu-item"
                          onClick={openClaims}
                        >
                          <span className="portal-menu-icon claim-portal">
                            &nbsp;
                          </span>
                          <div className="portal-tab-txt">
                            <p className="portal-menu-title">
                              Claims Portal
                            </p>
                            <span className="portal-menu-desc">
                              This is Claims Portal
                            </span>
                          </div>
                        </div>                        
                      </div>
                    ) : null}

                    {appList.indexOf("FIN") > -1 ? (
                      <div
                        className={`portal-menu ${
                          downtime && "error-state"
                        }`}
                      >
                        <div
                          title="Financial Portal"
                          className="portal-menu-item"
                          onClick={openFin}
                        >
                          <span className="portal-menu-icon financial-portal">
                            &nbsp;
                          </span>
                          <div className="portal-tab-txt">
                            <p className="portal-menu-title">
                              Financial Portal
                            </p>
                            <span className="portal-menu-desc">
                              This is Financial Portal
                            </span>
                          </div>
                        </div>                        
                      </div>
                    ) : null}

                    {appList.indexOf("WP") > -1 ? (
                      <div
                        className={`portal-menu ${
                          downtime && "error-state"
                        }`}
                      >
                        <div
                          title="Provider Claims Portal"
                          className="portal-menu-item"
                          onClick={openWP}
                        >
                          <span className="portal-menu-icon tmps-portal">
                            &nbsp;
                          </span>
                          <div className="portal-tab-txt">
                            <p className="portal-menu-title">
                              Provider Claims Portal
                            </p>
                            <span className="portal-menu-desc">
                              This is Provider Claims Portal
                            </span>
                          </div>
                        </div>                        
                      </div>
                    ) : null}
                          {appList.indexOf("MANAGEACCOUNT") > -1 ? (
                         <div
                         className={`portal-menu ${
                           downtime && "error-state"
                         }`}
                       >
                         <div
                           title="Manage User Portal"
                           className="portal-menu-item"
                           onClick={openManageAccont}
                         >
                           <span className="portal-menu-icon manage_user_portal">
                             &nbsp;
                           </span>
                           <div className="portal-tab-txt">
                             <p className="portal-menu-title">
                               Manage User Portal
                             </p>
                             <span className="portal-menu-desc">
                               This is Manage User Portal
                             </span>
                           </div>
                         </div>                        
                       </div>
                    ) : null}
                  </div>
                </div>
                <div className="user-logout text-center">
                <div className="logOut-btn mr-3" onClick={navigateToMyAccount}>
                    <span className="my-account"></span>
                    My Account
                  </div>
                  <div className="logOut-btn" onClick={logOut}>
                    <span className="fa-solid fa-right-from-bracket"></span>
                    Logout
                  </div>
                </div>
              </>
            ) : !isNormalLogin ? (
              <>
              <LoginPage setWarningMessages ={setWarningMessages} setErrorMessages={setErrorMessages} logOut={logOut} logOutClean={logOutClean}
              setInfoMessages = {setInfoMessages} infoMessages={infoMessages} setspinnerLoader={setspinnerLoader}
              setIsNormalLogin = {setIsNormalLogin} isNormalLogin={isNormalLogin} setAppList={setAppList} setUser={setUser}
              appList={appList} storeAppInfo={storeAppInfo} setIsLogin={setIsLogin} isLogin={isLogin} setSuccessMessages={setSuccessMessages} storeCred={storeCred}/>
              </>
            ) 
            :
            (
              <>
                <div className="container h-100 align-center">
                  <div className="welcome-user">
                    <h1>Welcome!</h1>
                    <p className="heading-text">
                      Please login to start your session
                    </p>

                    <div className="row">
                      <div className="col text-center">
                        <button
                          className="btn btn-primary"
                          onClick={SAMLSSOLogin}
                        >
                          SSO Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>                    
              </>
            )}                
          </div>
        </section>
      </div>
      }
    </div>
  </div>
</main>
}

<footer className="mt-0">       
            <div className="row m-0 mb-2 align-center">
              <div className="col-md-6">
                <p className="footer-text my-2">
                  ©2019 Conduent, Inc. All rights reserved. Conduent and
                  Conduent Agile Star are trademarks of Conduent, Inc.
                  and/or its subsidiaries in the United States and/or other
                  countries
                </p>
                <div align="center" className="mt-2">
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("PrivacyPolicy");
                    }}
                    title="Privacy Policy"
                  >
                    Privacy Policy
                  </a>
                </span>                
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("TermsofUse");
                    }}
                    title="Terms of Use"
                  >
                   Terms of Use
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("BrowserRequirements");
                    }}
                    title="Browser Requirements"
                  >
                    Browser Requirements
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink("AccessibilityCompliance");
                    }}
                    title="Accessibility Compliance"
                    className="lst-child"
                  >
                    Accessibility Compliance
                  </a>
                </span>
              </div>
              </div>
            </div>
      </footer>

</>
    
  );
};

export default HomePage;
